
import {
  apiDomainLists,
  apiDomainDelete,
  apiDomainAdd,
  apiDomainEdit
} from '@/api/domain'
import { Component, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
@Component({
  components: {
    lsPagination,
    LsDialog
  }
})
export default class DomainList extends Vue {
  $refs!: { dialogForm: any }
  /** S Data **/
  form = {
    domain: '',
    main: '',
    secondary: '',
    status: ''
  }

  dialogForm = {
    id: '',
    domain: '',
    status: 1
  }

  dialogFormRules = {
    domain: [
      { required: true, message: '请输入域名', trigger: 'blur' }
    ]
  }

  dialogFormVisible = false

  pager: RequestPaging = new RequestPaging()
  /** E Data **/

  reset (): void {
    this.form = {
      domain: '',
      main: '',
      secondary: '',
      status: ''
    }
    this.getLists(1)
  }

  // 获取管理员列表
  getLists (page?: number): void {
    if (!page) {
      this.pager.page = page || 1
    }
    const { domain, main, secondary, status } = this.form
    this.pager.request({
      callback: apiDomainLists,
      params: {
        domain,
        main,
        secondary,
        status
      }
    })
  }

  // 删除商城
  async onDelete (id: number): Promise<void> {
    await apiDomainDelete({ id }).then((res: any) => {
      this.getLists()
    })
  }

  dialog (row?: any | undefined): void {
    this.dialogForm = {
      id: '',
      domain: '',
      status: 1
    }
    if (row) {
      if (typeof row.id !== 'undefined') {
        this.dialogForm.id = row.id
      }
      if (typeof row.domain !== 'undefined') {
        this.dialogForm.domain = row.domain
      }
      if (typeof row.status !== 'undefined') {
        this.dialogForm.status = row.status
      }
    }
    this.dialogFormVisible = true
  }

  handStatus (status: number, row: any) {
    console.log(status, row)
    apiDomainEdit({ id: row.id, domain: row.domain, status }).then((res: any) => {
      this.getLists()
    })
  }

  // 编辑
  submitForm (): void {
    this.$refs.dialogForm.validate((valid: boolean) => {
      if (valid) {
        this.dialogFormVisible = false
        const { id, domain, status } = this.dialogForm
        if (id) {
          apiDomainEdit({ id, domain, status }).then((res: any) => {
            this.getLists()
          })
        } else {
          apiDomainAdd({ domain, status }).then((res: any) => {
            this.getLists()
          })
        }
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }

  // 添加
  toAdd (): void {
    const { domain, status } = this.dialogForm
    apiDomainAdd({ domain, status }).then((res: any) => {
      this.getLists(1)
    })
  }

  /** E Methods **/

  /** S ods **/
  created () {
    this.getLists()
  }
}
